import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Text, TextInput, TouchableOpacity } from "react-native-web";
import { FontAwesome5 } from "@expo/vector-icons";

const CashRegister = (props) => {
	// const [amount, setAmount] = useState("");

	let numbersCashRegister = [];

	const numericPad = () => {
		for (let index = 0; index < 10; index++) {
			numbersCashRegister.push(
				<TouchableOpacity
					key={index}
					onPress={() => {
						if (props.amount.length < 8) props.amountValue(props.amount + index);
					}}
					style={[styles.numericButton, index === 0 ? styles.zeroButton : ""]}
				>
					<Text style={{ color: "white", fontSize: "larger" }}>{index}</Text>
				</TouchableOpacity>
			);
		}

		return <div style={styles.wrapper}>{numbersCashRegister}</div>;
	};

	return (
		<View>
			<div style={styles.flexRow}>
				<TextInput style={styles.textInput} value={(props.amount / 100).toFixed(2) + " " + props.currency}></TextInput>
				<TouchableOpacity
					style={styles.deleteButton}
					onPress={() => {
						props.amountValue(props.amount.substring(0, props.amount.length - 1));
					}}
				>
					<FontAwesome5 name="backspace" size={24} color="white" />
				</TouchableOpacity>
			</div>

			{numericPad()}
		</View>
	);
};

const styles = StyleSheet.create({
	flexRow: {
		display: "flex",
		flexDirection: "row",
		height: 40
	},
	textInput: {
		padding: ".5rem",
		width: "90%",
		borderColor: "white",
		borderWidth: 1,
		color: "white",
		textAlign: "right",
		fontSize: "x-large"
	},
	deleteButton: {
		justifyContent: "center",
		alignItems: "center",
		marginLeft: ".3rem"
	},
	alignCenter: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	numericButton: {
		display: "flex",
		width: "100%",
		height: "4rem",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgba(211,211,211,0.6)",
		padding: ".5rem",
		fontWeight: 900,
		border: "3px solid",
		borderRadius: "10px"
	},
	wrapper: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr 1fr",
		marginTop: ".5rem",
		columnGap: ".5rem",
		rowGap: ".5rem"
	},
	zeroButton: {
		gridRow: 4,
		gridColumn: "2/3"
	}
});

export default CashRegister;
