import { StatusBar } from "expo-status-bar";
import { useEffect, useState } from "react";
import { StyleSheet, View, Text, Dimensions } from "react-native";
import { ActivityIndicator, Modal, TextInput, TouchableOpacity, TouchableWithoutFeedback } from "react-native-web";
import { FontAwesome5 } from "@expo/vector-icons";
import CashRegister from "./CashRegister";
import * as Localization from "expo-localization";
import { translations } from "./translations";
import { I18n } from "i18n-js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Ionicons } from "@expo/vector-icons";
import Transactions from "./Transactions";
import { API_URL } from "@env";

const i18n = new I18n(translations);
const { width, height } = Dimensions.get("window");
const STATUS_NEW = "new"; // just instantiated
const STATUS_CREATED = "created"; // a payment intent has been created on the gateway
const STATUS_PROCESSED = "processed"; // the payment has been processed on the terminal
const STATUS_CANCELLED_ACTION = "cancelled_action"; // the action on the terminal has been cancelled, intent cancellation to be done
const STATUS_CANCELLED_INTENT = "cancelled_intent"; // the payment intent has been cancelled
const STATUS_CAPTURED = "captured"; // the payment intent has been captured
const STATUS_ERROR = "error"; // an error has occurred during intent processing, refer to last_error field
const PAYMENT_SENT = "payment_sent"; // payment accepted to be processed

export default function App() {
	const params = new URLSearchParams(window.location.search);

	const [token, setToken] = useState("");
	const [amountValue, setAmountValue] = useState("");
	const [showLoading, setShowLoading] = useState(false);
	const [currency, setCurrency] = useState("");
	const [restaurantName, setRestaurantName] = useState("");
	const [locale, setLocale] = useState(Localization.locale);
	const [password, setPassword] = useState("");
	const [modalVisible, setModalVisible] = useState(false);
	const [modalPassword, setModalPassword] = useState(false);
	const [modalMessage, setModalMessage] = useState("");
	const [successIcon, setSuccessIcon] = useState(false);
	const [failIcon, setFailIcon] = useState(false);
	const [paymentSent, setpaymentSent] = useState(false);
	const [showTransactions, setShowTransactions] = useState(false);
	const [showResyncButton, setShowResyncButton] = useState(false);
	const [skipPaymentRequest, setSkipPaymentRequest] = useState(true);
	const [wrongPassword, setWrongPassword] = useState(false);
	const [canceling, setCanceling] = useState(false);

	i18n.enableFallback = true;
	i18n.locale = locale;
	let interval;

	useEffect(() => {
		async function init() {
			let token = await detectToken();
			if (token) {
				getLocalstorage(token).then((value) => {
					setPassword(value);
					getRestaurantInfo(value, token);
				});
			}
		}
		init();
	}, []);

	useEffect(() => {
		if (paymentSent) {
			startInterval();
		} else {
			setSkipPaymentRequest(false);
			setWrongPassword(false);
			setSuccessIcon(false);
		}
	}, [paymentSent]);

	const detectToken = async () => {
		let paramToken = params.get("token");
		if (paramToken) {
			setLocalstorage("lastToken", paramToken);
		} else {
			paramToken = await getLocalstorage("lastToken");
		}
		setToken(paramToken);
		return paramToken;
	};

	const setLocalstorage = async (key, value) => {
		try {
			await AsyncStorage.setItem(key, value);
		} catch (e) {
			alert("Failed to save the data to the storage");
		}
	};

	const getLocalstorage = async (key) => {
		try {
			let value = await AsyncStorage.getItem(key);
			return value;
		} catch (e) {
			alert("Failed to fetch the input from storage");
		}
	};

	const initTerminal = () => {
		if (!password) {
			setModalPassword(true);
			return;
		}
		setSkipPaymentRequest(false);
		requestPayment();
	};

	const resync = () => {
		setShowLoading(true);
		setShowResyncButton(false);
		startInterval();
	};

	const startInterval = () => {
		interval = setInterval(() => {
			checkStatus();
		}, 5000);
	};

	const savePassword = async () => {
		await setLocalstorage(token, password);
		setModalPassword(false);
		if (!skipPaymentRequest) {
			requestPayment();
		} else {
			checkStatus(true);
		}
	};

	const resetPassword = () => {
		AsyncStorage.removeItem(token).then(() => {
			setPassword("");
			setWrongPassword(true);
			setModalPassword(true);
		});
	};

	const getRestaurantInfo = async (password, token) => {
		const header = {
			method: "GET",
			headers: new Headers({
				Authorization: token
			})
		};
		return fetch(API_URL + "/api/terminal/restaurant_info", header)
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then((result) => {
				setRestaurantName(result?.name);
				setCurrency(result?.currency);
				if (result?.reader?.status === "offline") {
					alert(i18n.t("reader_offline"));
				} else {
					// check pending payment
					if (password) {
						checkStatus(true, password, token);
					} else {
						setSkipPaymentRequest(true);
						setModalPassword(true);
					}
				}
				return result;
			})
			.catch((response) => {
				if (response instanceof TypeError) {
				} else {
					response?.json().then((result) => {
						// menage error
						return alert(i18n.t(result.error_code));
					});
				}
			});
	};

	const requestPayment = async () => {
		setShowLoading(true);
		setpaymentSent(false);
		setCanceling(false);
		let amount = amountValue || 0;
		let formdata = new FormData();
		formdata.append("password", password);
		formdata.append("amount", parseInt(amount));
		const header = {
			method: "POST",
			headers: new Headers({
				Authorization: token
			}),
			body: formdata
		};
		return fetch(API_URL + "/api/terminal/request_payment", header)
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then((result) => {
				if (result.status === PAYMENT_SENT) {
					setpaymentSent(true);
				}
				return result;
			})
			.catch((response) => {
				if (response instanceof TypeError) {
				} else {
					response?.json().then((result) => {
						if (result?.error_code === "failed_validation" || result?.error_code === "wrong_password") {
							clearInterval(interval);
						}
						if (result?.error_code === "wrong_password") {
							resetPassword();
						}
						// menage error
						return alert(i18n.t(result.error_code));
					});
				}
				setShowLoading(false);
			});
	};

	const checkStatus = async (firstCheck = false, pwd = null, tokenParam = null) => {
		setSuccessIcon(false);
		let formdata = new FormData();
		formdata.append("password", pwd || password);
		const header = {
			method: "POST",
			headers: new Headers({
				Authorization: tokenParam || token
			}),
			body: formdata
		};
		return fetch(API_URL + "/api/terminal/check_status", header)
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then((result) => {
				if (!firstCheck) {
					if (result.status !== STATUS_PROCESSED) {
						// stop polling
						clearInterval(interval);
						setShowLoading(false);
					}
					if (result.status === STATUS_CAPTURED) {
						successPayment();
					}
					if (result.status === STATUS_CANCELLED_ACTION || result.status === STATUS_CANCELLED_INTENT) {
						setModalMessage(i18n.t("payment_canceled"));
						setModalVisible(true);
					}
					if (result.status === STATUS_ERROR) {
						setModalMessage(i18n.t(result.last_error));
						setModalVisible(true);
					}
				} else if (result.status === STATUS_PROCESSED) {
					setpaymentSent(true);
					setShowLoading(true);
				}
				return result;
			})
			.catch((response) => {
				if (response instanceof TypeError) {
					// stop polling
					setShowLoading(false);
					clearInterval(interval);
					setShowResyncButton(true);
				} else {
					response?.json().then((result) => {
						clearInterval(interval);
						if (result?.error_code === "wrong_password") {
							resetPassword();
						}
						if (result?.status) {
							result.error_code = result?.last_error;
						}
						return !firstCheck ? alert(i18n.t(result.error_code)) : "";
					});
				}
			});
	};

	const cancelPayment = async () => {
		if (!canceling) {
			setCanceling(true);
			let formdata = new FormData();
			formdata.append("password", password);
			const header = {
				method: "POST",
				headers: new Headers({
					Authorization: token
				}),
				body: formdata
			};
			return fetch(API_URL + "/api/terminal/request_cancel_action", header)
				.then((response) => {
					if (response.ok) {
						return response.json();
					}
					return Promise.reject(response);
				})
				.then((result) => {
					return result;
				})
				.catch((response) => {
					if (response instanceof TypeError) {
					} else {
						response?.json().then((result) => {
							// menage error
							return alert(i18n.t(result.error_code));
						});
					}
					setShowLoading(false);
				});
		}
	};

	const updatePassword = (value) => {
		setPassword(value);
		setWrongPassword(false);
	};

	const successPayment = () => {
		setSuccessIcon(true);
		setModalMessage(i18n.t("payment_successfull"));
		setModalVisible(true);
		setAmountValue("");
	};

	return (
		<View style={[width > 500 ? styles.containerDesktop : styles.container]}>
			{showResyncButton && (
				<div style={{ display: "flex", justifyContent: "end" }}>
					<Ionicons name="sync-circle-sharp" size={24} color="white" onPress={() => resync()} />
				</div>
			)}
			{/* MODAL RESPONSE PAYMENT */}
			<Modal visible={modalVisible} animationType="fade" transparent overFullScreen>
				<TouchableOpacity onPress={() => setModalVisible(false)} style={styles.modalBackDrop} activeOpacity={1}>
					<TouchableWithoutFeedback>
						<View style={styles.modalContainer}>
							<TouchableWithoutFeedback>
								<View style={styles.boxCentered}>
									{successIcon && <FontAwesome5 name="thumbs-up" size={28} color="green" style={{ marginBottom: "1rem" }} />}
									{failIcon && <FontAwesome5 name="thumbs-down" size={28} color="red" style={{ marginBottom: "1rem" }} />}
									<Text style={{ fontSize: 18 }}>{modalMessage}</Text>
								</View>
							</TouchableWithoutFeedback>
							<div style={styles.modalButton}>
								<TouchableOpacity onPress={() => setModalVisible(false)} style={{ display: "flex", width: "100%", alignItems: "center" }}>
									<Text style={{ fontSize: 20, color: "blue", padding: ".8rem" }}>OK</Text>
								</TouchableOpacity>
							</div>
						</View>
					</TouchableWithoutFeedback>
				</TouchableOpacity>
			</Modal>
			{/* MODAL PASSWORD */}
			<Modal visible={modalPassword} animationType="fade" transparent overFullScreen>
				<TouchableOpacity onPress={() => setModalPassword(false)} style={styles.modalBackDrop} activeOpacity={1}>
					<TouchableWithoutFeedback>
						<View style={styles.modalContainer}>
							<div style={styles.boxCenteredRow}>
								<TouchableWithoutFeedback>
									<View style={{ display: "flex", flex: 1, marginLeft: ".3rem", marginRight: ".3rem" }}>
										<TextInput
											style={[styles.password, wrongPassword ? styles.invalid : ""]}
											placeholder="password"
											onChangeText={updatePassword}
											value={password || ""}
										></TextInput>
										{wrongPassword && <Text style={styles.errorMsg}>{i18n.t("wrong_password")}</Text>}
									</View>
								</TouchableWithoutFeedback>
							</div>

							<div style={styles.modalButton}>
								<TouchableOpacity onPress={() => savePassword()} style={{ display: "flex", width: "100%", alignItems: "center" }}>
									<Text style={{ fontSize: 20, color: "blue", padding: ".8rem" }}>OK</Text>
								</TouchableOpacity>
							</div>
						</View>
					</TouchableWithoutFeedback>
				</TouchableOpacity>
			</Modal>
			{/* TRANSACTIONS */}
			{showTransactions && <Transactions showTransactions={setShowTransactions} currency={currency} password={password}></Transactions>}
			{/* LOADING PAGE */}
			{showLoading && (
				<div>
					<Text style={styles.paymentStarted}>{canceling ? i18n.t("cancel_in_progress") : i18n.t("payment_started")}</Text>
					<ActivityIndicator size="large" style={[styles.backgroundLoading, width > 500 ? styles.roundedBorder : ""]} />
					<div style={{ position: "absolute", bottom: "15rem", width: "100%" }}>
						<TouchableOpacity
							onPress={() => cancelPayment()}
							style={[styles.cancelPayment, !paymentSent || canceling ? styles.buttonDisabled : ""]}
							disabled={!paymentSent || canceling}
						>
							<Text style={{ fontSize: 20, color: "#fff" }}>{i18n.t("cancel_payment")}</Text>
						</TouchableOpacity>
					</div>
				</div>
			)}
			<div style={styles.textCenter}>
				<Text style={styles.restaurantName}>{restaurantName}</Text>
			</div>
			<div style={{ ...styles.textRight, ...styles.width90 }}>
				<Text style={{ fontSize: 16, color: "#fff" }}>{i18n.t("insert_payment")}</Text>
			</div>
			<CashRegister amountValue={setAmountValue} amount={amountValue} currency={currency}></CashRegister>
			<div style={styles.rowMargin}>
				<TouchableOpacity onPress={() => initTerminal()} style={styles.customButton}>
					<Text style={{ fontSize: 20, color: "#fff" }}>{i18n.t("pay")}</Text>
				</TouchableOpacity>
			</div>
			<hr style={{ width: "100%" }}></hr>
			<div style={{ ...styles.rowMargin, ...styles.justifyCenter }}>
				<TouchableOpacity onPress={() => setShowTransactions(true)} style={styles.functionalityButtons}>
					<Text style={{ fontSize: 16, color: "white", padding: ".8rem" }}>{i18n.t("transaction_list")}</Text>
				</TouchableOpacity>
			</div>

			<StatusBar style="auto" />
		</View>
	);
}

const colorCancelButton = "rgb(193,0,4)";
const colorPayButton = "rgb(150,205,50)";
const functionalityColorButtons = "rgb(38,36,137)";

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "black",
		padding: ".5rem"
	},
	containerDesktop: {
		flex: 1,
		width: "30rem",
		backgroundColor: "black",
		margin: ".5rem",
		alignSelf: "center",
		border: "2px solid",
		borderRadius: "25px",
		color: "black",
		padding: ".5rem"
	},
	restaurantName: {
		marginRight: ".5rem",
		fontWeight: "bold",
		fontSize: "x-large",
		color: "white"
	},
	text: {
		color: "gray"
	},
	margin20: {
		marginBottom: "20rem"
	},
	width90: {
		width: "90%"
	},
	rowMargin: {
		display: "flex",
		marginTop: "1rem",
		marginBottom: "1rem"
	},
	textCenter: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginBottom: ".5rem"
	},
	textRight: {
		display: "flex",
		justifyContent: "end",
		alignItems: "center",
		marginBottom: ".5rem"
	},
	customButton: {
		display: "flex",
		flex: "1",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: colorPayButton,
		padding: ".5rem",
		marginLeft: "3rem",
		marginRight: "3rem",
		borderRadius: "10px",
		boxShadow: "0px 5px 15px rgba(255,255,255, 0.2)"
	},
	buttonDisabled: {
		opacity: 0.3
	},
	backgroundLoading: {
		top: 0,
		left: 0,
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: 9998,
		backgroundColor: "rgba(0,0,0,1)"
	},
	cancelPayment: {
		display: "flex",
		alignItems: "center",
		backgroundColor: colorCancelButton,
		padding: ".5rem",
		zIndex: 9999,
		marginLeft: "3rem",
		marginRight: "3rem",
		borderRadius: "10px"
	},
	password: {
		padding: ".5rem",
		width: "100%",
		borderColor: "gray",
		borderWidth: 1,
		color: "gray"
	},
	invalid: {
		borderColor: "red"
	},
	errorMsg: {
		height: ".8rem",
		color: "red",
		fontSize: "x-small"
	},
	loading: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: "center",
		justifyContent: "center"
	},
	modalBackDrop: {
		flex: 1,
		backgroundColor: "rgba(0,0,0,0.60)",
		justifyContent: "center",
		alignItems: "center"
	},
	modalContainer: {
		width: "280px",
		height: "280px",
		backgroundColor: "white",
		border: "2px solid",
		borderRadius: "25px",
		color: "black"
	},
	boxCentered: {
		display: "flex",
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		margin: "1rem"
	},
	boxCenteredRow: {
		display: "flex",
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		margin: "1rem"
	},
	modalButton: {
		borderStyle: "solid",
		borderTopWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0,
		borderLeftWidth: 0,
		borderColor: "darkgray",
		margin: ".2rem"
	},
	roundedBorder: {
		borderRadius: "20px"
	},
	functionalityButtons: {
		display: "flex",
		flex: 1,
		maxWidth: "fit-content",
		backgroundColor: functionalityColorButtons,
		marginRight: ".3rem",
		borderRadius: "10px"
	},
	justifyCenter: {
		justifyContent: "center"
	},
	paymentStarted: {
		position: "relative",
		display: "flex",
		justifyContent: "center",
		marginTop: "8rem",
		fontSize: "x-large",
		color: "white",
		zIndex: 9999
	}
});
