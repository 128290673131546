import React, { useEffect, useState } from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { ActivityIndicator, Modal, ScrollView, Text, TouchableOpacity } from "react-native-web";
import { AntDesign } from "@expo/vector-icons";
import * as Localization from "expo-localization";
import { translations } from "./translations";
import { I18n } from "i18n-js";
import { DataTable } from "react-native-paper";
import DropDownPicker from "react-native-dropdown-picker";
import Moment from "moment";
import { API_URL } from "@env";

const i18n = new I18n(translations);
const { width, height } = Dimensions.get("window");
const STATUS_CANCELLED_ACTION = "cancelled_action"; // the action on the terminal has been cancelled, intent cancellation to be done
const STATUS_CANCELLED_INTENT = "cancelled_intent"; // the payment intent has been cancelled
const STATUS_CAPTURED = "captured"; // the payment intent has been captured
const STATUS_ERROR = "error"; // an error has occurred during intent processing, refer to last_error field

const Transactions = (props) => {
	const params = new URLSearchParams(window.location.search);
	const token = params.get("token");
	const [items, setItems] = useState([]);
	const [page, setPage] = useState(0);
	const [lastPage, setLastPage] = useState(page);
	const [optionsPerPage, setOptionsPerPage] = useState([
		{ label: 1, value: 1 },
		{ label: 10, value: 10 },
		{ label: 30, value: 30 }
	]);
	const [itemsPerPage, setItemsPerPage] = useState(optionsPerPage[1].value);
	const [open, setOpen] = useState(false);
	const from = page;
	const [showLoading, setShowLoading] = useState(false);

	const [locale, setLocale] = useState(Localization.locale);

	i18n.enableFallback = true;
	i18n.locale = locale;

	useEffect(() => {
		getTransactions(page + 1, itemsPerPage);
	}, [itemsPerPage, page]);

	const getTransactions = (page, perPage) => {
		let formdata = new FormData();
		formdata.append("password", props.password);
		const header = {
			method: "POST",
			headers: new Headers({
				Authorization: token
			}),
			body: formdata
		};
		return fetch(API_URL + "/api/terminal/get_transactions?per_page=" + perPage + "&page=" + page, header)
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject(response);
			})
			.then((result) => {
				setItems(result.data);
				setLastPage(result?.meta?.last_page);
				return result;
			})
			.catch((response) => {
				if (response instanceof TypeError) {
				} else {
					response?.json().then((result) => {
						// menage error
						return alert(i18n.t(result.error_code));
					});
				}
			});
	};

	const refoundTransaction = (transactionId, formattedDate, formattedAmount) => {
		if (confirm(i18n.t("refund_confirm", { transactionAmount: formattedAmount, formattedDate: formattedDate }))) {
			setShowLoading(true);
			let formdata = new FormData();
			formdata.append("password", props.password);
			formdata.append("transaction_id", transactionId);
			const header = {
				method: "POST",
				headers: new Headers({
					Authorization: token
				}),
				body: formdata
			};
			return fetch(API_URL + "/api/terminal/refund_transaction", header)
				.then((response) => {
					if (response.ok) {
						return response.json();
					}
					return Promise.reject(response);
				})
				.then((result) => {
					setShowLoading(false);
					getTransactions(page, itemsPerPage);
					return result;
				})
				.catch((response) => {
					setShowLoading(false);
					response?.json().then((result) => {
						// menage error
						return alert(i18n.t(result.error_code));
					});
				});
		}
	};

	const processStatus = (row) => {
		if (row?.refunded) {
			return i18n.t("refunded");
		}
		if (row?.status === STATUS_CANCELLED_ACTION || row?.status === STATUS_CANCELLED_INTENT) {
			return i18n.t("canceled");
		}
		if (row?.status === STATUS_CAPTURED) {
			return i18n.t("completed");
		}
		if (row?.status === STATUS_ERROR) {
			return i18n.t("in_error");
		}
		return row?.status || "";
	};

	const updateItemPerPage = (value) => {
		setItemsPerPage(value);
		setPage(0);
	};

	return (
		<Modal>
			<View style={[width > 500 ? styles.containerDesktop : styles.container]}>
				{/* LOADING PAGE */}
				{showLoading && <ActivityIndicator size="large" style={styles.backgroundLoading} />}
				<div style={styles.topHeader}>
					<AntDesign name="closesquare" size={36} color="white" onPress={() => props.showTransactions(false)} />
				</div>
				<DataTable style={{ marginBottom: "2rem" }}>
					<div style={styles.bottomBar}>
						<Text style={{ color: "white" }}>{i18n.t("row_per_page")}</Text>
						<div style={{ width: "5rem", marginLeft: ".8rem" }}>
							<DropDownPicker
								style={{ minHeight: "35px" }}
								open={open}
								value={itemsPerPage}
								items={optionsPerPage}
								setOpen={setOpen}
								setValue={updateItemPerPage}
								setItems={setOptionsPerPage}
							/>
						</div>
						<DataTable.Pagination
							textStyle={{ color: "white" }}
							page={page}
							numberOfPages={lastPage}
							onPageChange={(page) => setPage(page)}
							label={`${+from + 1}-${lastPage} of ${lastPage}`}
							optionsPerPage={optionsPerPage}
							itemsPerPage={itemsPerPage}
							setItemsPerPage={setItemsPerPage}
							showFastPagination
						/>
					</div>
					<DataTable.Header style={styles.borderBottom}>
						<DataTable.Title textStyle={{ color: "white" }}>{i18n.t("amount")}</DataTable.Title>
						<DataTable.Title textStyle={{ color: "white" }}>{i18n.t("status")}</DataTable.Title>
						<DataTable.Title textStyle={{ color: "white" }}>{i18n.t("createdAt")}</DataTable.Title>
						<DataTable.Title></DataTable.Title>
					</DataTable.Header>
					<div style={width > 500 ? styles.scrollableDesktop : styles.scrollable}>
						{items?.slice(0, items?.length).map((item) => {
							let currentDate = Moment.utc(item.created_at).toDate().toISOString();
							let formatted = currentDate.substring(8, 10) + "/" + currentDate.substring(5, 7) + " " + currentDate.substring(11, 16);
							let formattedAmount = (item.amount / 100).toFixed(2) + " " + props.currency;
							return (
								<DataTable.Row key={item.id} style={styles.borderBottom}>
									<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
										<div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
											<DataTable.Cell>
												<Text style={{ color: "white", fontSize: 12 }}>{formattedAmount}</Text>
											</DataTable.Cell>
											<DataTable.Cell>
												<Text style={{ color: "white", fontSize: 12 }}>{processStatus(item)}</Text>
											</DataTable.Cell>
											<DataTable.Cell>
												<Text style={{ color: "white", fontSize: 12 }}>{formatted}</Text>
											</DataTable.Cell>
											<DataTable.Cell numeric>
												{item.status === STATUS_CAPTURED && !item.refunded && (
													<TouchableOpacity
														onPress={() => refoundTransaction(item.id, formatted, formattedAmount)}
														style={[styles.refoundPayment]}
													>
														<Text style={{ fontSize: 12 }}>{i18n.t("refund")}</Text>
													</TouchableOpacity>
												)}
											</DataTable.Cell>
										</div>
										{item.last_error && (
											<DataTable.Row style={{ padding: 0 }}>
												<DataTable.Cell>
													<Text style={{ fontSize: 12, color: "red" }}>{item.last_error}</Text>
												</DataTable.Cell>
											</DataTable.Row>
										)}
									</div>
								</DataTable.Row>
							);
						})}
					</div>
				</DataTable>
			</View>
		</Modal>
	);
};
const colorCancelButton = "rgb(193,0,4)";

const styles = StyleSheet.create({
	borderBottom: {
		borderBottomColor: "gray"
	},
	container: {
		backgroundColor: "black",
		height: "100vh",
		width: "100vw"
	},
	containerDesktop: {
		backgroundColor: "black",
		flex: 1,
		width: "30rem",
		height: "100%",
		margin: ".5rem",
		alignSelf: "center",
		border: "2px solid",
		borderRadius: "25px",
		color: "black",
		padding: ".5rem"
	},
	scrollable: {
		overflowY: "auto",
		top: "10rem",
		left: 0,
		bottom: 0,
		right: 0,
		position: "fixed",
		marginBottom: "3rem"
	},
	scrollableDesktop: {
		overflowY: "auto",
		height: "calc(100vh - 200px)"
	},
	topHeader: {
		display: "flex",
		justifyContent: "end"
	},
	bottomBar: {
		display: "flex",
		flex: 1,
		justifyContent: "end",
		alignItems: "center",
		marginTop: "1rem"
	},
	backgroundLoading: {
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: 9998,
		backgroundColor: "black"
	},
	refoundPayment: {
		padding: ".4rem",
		color: "white",
		backgroundColor: colorCancelButton,
		borderRadius: "10px"
	}
});

export default Transactions;
