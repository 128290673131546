const translations = {
	"en-US": {
		pay: "Pay",
		currency: "Currency",
		amount: "Amount",
		status: "Status",
		refunded: "Refunded",
		lastError: "Last error",
		createdAt: "Created at",
		yes: "Yes",
		no: "No",
		cancel_payment: "Cancel payment",
		reader_not_found: "Terminal reader not found",
		reader_not_owned: "Site id doesn't match",
		reader_without_location: "Location not found",
		connection_token_generation: "connection_token_generation",
		reader_is_offline: "Terminal reader offline",
		cannot_create_intent: "Cannot create intent",
		sitenotvalid: "Site config not valid",
		wrong_password: "Wrong password",
		stripe_not_enabled_for_site: "Stripe not enabled",
		reader_not_owned_by_site: "Terminal reader not owned",
		unauthorized: "unauthorized",
		site_not_found: "Site not found",
		failed_validation: "Amount or password invalid",
		no_payment_found: "No payment found",
		payment_successfull: "Payment successfull",
		payment_canceled: "Payment successfully canceled",
		action_not_found: "Impossible to cancel at the moment",
		error_sending_payment_to_reader: "Error sending payment to reader",
		transaction_list: "Transaction list",
		row_per_page: "Rows per page",
		canceled: "Canceled",
		completed: "Completed",
		in_error: "In error",
		refund: "Refund",
		change_password: "Change password",
		insert_payment: "Please insert the amount of the payment:",
		payment_started: "Processing payment...",
		refund_confirm: "Are you sure you want to refund the transaction?\n {{transactionAmount}} registered on {{formattedDate}}",
		reader_busy: "Terminal reader busy",
		error_during_cancelation: "Error during cancelation",
		card_declined: "Card declined",
		cancel_in_progress: "Cancel in progress..."
	},
	en: {
		pay: "Pay",
		currency: "Currency",
		amount: "Amount",
		status: "Status",
		refunded: "Refunded",
		lastError: "Last error",
		createdAt: "Created at",
		yes: "Yes",
		no: "No",
		cancel_payment: "Cancel payment",
		reader_not_found: "Terminal reader not found",
		reader_not_owned: "Site id doesn't match",
		reader_without_location: "Location not found",
		connection_token_generation: "connection_token_generation",
		reader_is_offline: "Terminal reader offline",
		cannot_create_intent: "Cannot create intent",
		sitenotvalid: "Site config not valid",
		wrong_password: "Wrong password",
		stripe_not_enabled_for_site: "Stripe not enabled",
		reader_not_owned_by_site: "Terminal reader not owned",
		unauthorized: "unauthorized",
		site_not_found: "Site not found",
		failed_validation: "Amount or password invalid",
		no_payment_found: "No payment found",
		payment_successfull: "Payment successfull",
		payment_canceled: "Payment successfully canceled",
		action_not_found: "Impossible to cancel at the moment",
		error_sending_payment_to_reader: "Error sending payment to reader",
		transaction_list: "Transaction list",
		row_per_page: "Rows per page",
		canceled: "Canceled",
		completed: "Completed",
		in_error: "In error",
		refund: "Refund",
		change_password: "Change password",
		insert_payment: "Please insert the amount of the payment:",
		payment_started: "Processing payment...",
		refund_confirm: "Are you sure you want to refund the transaction?\n {{transactionAmount}} registered on {{formattedDate}}",
		reader_busy: "Terminal reader busy",
		error_during_cancelation: "Error during cancelation",
		card_declined: "Card declined",
		cancel_in_progress: "Cancel in progress..."
	},
	it: {
		pay: "Paga",
		currency: "Valuta",
		amount: "Importo",
		status: "Stato",
		refunded: "Rimborsato",
		lastError: "Ultimo errore",
		createdAt: "Creato il",
		yes: "Si",
		no: "No",
		cancel_payment: "Annulla pagamento",
		reader_not_found: "Terminale di pagamento non trovato",
		reader_not_owned: "Site id non valido",
		reader_without_location: "Location non trovata",
		connection_token_generation: "connection_token_generation",
		reader_is_offline: "Terminale di pagamento scollegato",
		cannot_create_intent: "Impossibile creare l'intenzione di pagamento",
		sitenotvalid: "Configurazioni del site non valide",
		wrong_password: "Password errata",
		stripe_not_enabled_for_site: "Stripe non abilitato",
		reader_not_owned_by_site: "Terminale di pagamento non di proprietà",
		unauthorized: "Non autorizzato",
		site_not_found: "Sito non trovato",
		failed_validation: "Importo o password errati",
		no_payment_found: "Pagamento non trovato",
		payment_successfull: "Pagamento andato a buon fine",
		payment_canceled: "Pagamento annullato con successo",
		action_not_found: "Impossibile annullare ora",
		error_sending_payment_to_reader: "Errore nell'invio del pagamento al terminale",
		transaction_list: "Lista transazioni",
		row_per_page: "Righe per pagina",
		canceled: "Cancellato",
		completed: "Completato",
		in_error: "In errore",
		refund: "Rimborsa",
		change_password: "Reimposta password",
		insert_payment: "Inserisci l'importo del pagamento:",
		payment_started: "Pagamento in corso...",
		refund_confirm: "Confermi il rimborso della transazione?\n {{transactionAmount}} del {{formattedDate}}",
		reader_busy: "Terminale di pagamento occupato",
		error_during_cancelation: "Errore durante la cancellazione",
		card_declined: "Carta rifiutata",
		cancel_in_progress: "Annulamento in corso..."
	}
};

export { translations };
