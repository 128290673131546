import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as PaperProvider } from "react-native-paper";
import App from "./App";

// const rootElement = document?.getElementById("root");
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<PaperProvider>
		<App />
	</PaperProvider>
);
